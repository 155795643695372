<template>
  <section>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link to="/" class="d-flex align-center">
              <v-img
                max-height="200px"
                max-width="200px"
                alt="logo"
                contain
                class="me-3"
                :src="logo"
              ></v-img>
            </router-link>
          </v-card-title>

          <!-- title -->

          <!-- login form -->
          <v-card-text>
            <v-form>
              <v-row>
                <div class="col col-12">
                  <v-text-field
                    prepend-inner-icon="mdi-account-outline"
                    outlined
                    v-model="email"
                    label="Email"
                    placeholder="nomprenom@gmail.com"
                    hide-details
                    class="mb-3"
                  ></v-text-field>
                </div>
                <div class="col col-12">
                  <v-text-field
                    prepend-inner-icon="mdi-lock-outline"
                    outlined
                    v-model="password"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    label="Mot de passe"
                    placeholder="············"
                    :append-icon="
                      isPasswordVisible
                        ? icons.mdiEyeOffOutline
                        : icons.mdiEyeOutline
                    "
                    hide-details
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </div>
                <div class="col col-12" style="text-align: end;">
                  <a @click="dialogForgetPWD = true" style="color: gray;">
                    Mot de passe oublié ?
                  </a>
                </div>

                <div class="col col-12">
                  <v-btn
                    outlined
                    depressed
                    rounded
                    size="x-large"
                    block
                    style="background-color: #A8FE39; color:#0B0B0B"
                    @click="login"
                    :loading="loading"
                  >
                    Connexion
                  </v-btn>
                </div>
              </v-row>
            </v-form>
          </v-card-text>
          <!-- social links -->
        </v-card>
      </div>

      <!-- background triangle shape  
    <img class="auth-mask-bg" height="173" /> -->

      <!-- tree  -->
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialogForgetPWD" max-width="600">
        <v-card color="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar color="primary" class="mt-10" icon size="50">
              <v-icon size="2rem" color="white">
                {{ icons.mdiHelpCircleOutline }}
              </v-icon>
            </v-avatar>
            <h6 class="text-xl mt-4 font-weight-small">
              Veuillez entrer votre adresse e-mail pour rechercher votre compte.
            </h6>
          </v-card-text>

          <v-card-text class="d-flex align-center">
            <v-text-field
              prepend-inner-icon="mdi-account-outline"
              outlined
              v-model="email"
              label="Email"
              placeholder="nomprenom@gmail.com"
              hide-details
              class="mb-3"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <v-btn
              color="primary"
              class="mt-4"
              style="background-color: #A8FE39; color:#0B0B0B"
              @click="resetPassword"
            >
              Envoyer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>

<script>
import Constant from "@/utils/constants";
import axios from "axios";
import logo from "@/assets/images/logo.png";
import store from "../../store/index";
import {
  mdiHelpCircleOutline,
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import { ref } from "@vue/composition-api";
import firebase from "firebase/compat/app";
import "firebase/auth";


export default {
  setup() {
    const isPasswordVisible = ref(false);
    const email = ref("");
    const password = ref("");
    const dialogForgetPWD = ref(false);
    const loading = ref(false);
    const icons = {
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiHelpCircleOutline,
    };

    

    async function resetPassword() {
      try {
        await firebase.auth().sendPasswordResetEmail(email.value);
        alert("E-mail de réinitialisation du mot de passe envoyé. Vérifiez votre boîte de réception.");
        dialogForgetPWD.value = false;
      } catch (error) {
        alert(error.message);
        dialogForgetPWD.value = false;
      }
    }

    async function login() {
      loading.value = true;
      try {
        // Fetch user details from your backend
        const response = await axios.get(`${Constant.QORANI}user/getByEmail/${email.value}`);
        const userDetails = response.data;

        if (userDetails) {
          if (userDetails.role === "ADMIN" || userDetails.role === "LIBRARY") {
            await firebase.auth().signInWithEmailAndPassword(email.value, password.value);
            const userEmail = firebase.auth().currentUser.email;

            // Store user email in local storage or Vuex store
            localStorage.setItem("userEmail", userEmail);
            // Store user role in local storage or Vuex store
            localStorage.setItem('role', userDetails.role);
            // Store userId in local storage or Vuex store
            localStorage.setItem('ownerId',userDetails.userId);
            store.commit("setUserEmail", userEmail);

            // Redirect based on the role
            if (userDetails.role === "ADMIN") {
              this.$router.push('/Level'); // Redirect to the admin dashboard
            } else if (userDetails.role === "LIBRARY") {
              this.$router.push('/myLibrary'); // Redirect to the library dashboard
            }
          } else {
            alert("Accès refusé. Veuillez vérifier vos informations de connexion ou contacter l'administrateur.");
          }
        } else {
          alert("Nous sommes désolés, mais l'adresse e-mail que vous avez fournie est introuvable dans notre système. Veuillez vérifier l'orthographe de votre adresse e-mail et réessayer.");
        }
      } catch (error) {
        alert("Nous sommes désolés, mais l'adresse e-mail que vous avez fournie est introuvable dans notre système. Veuillez vérifier l'orthographe de votre adresse e-mail et réessayer.");
        console.error("An error occurred:", error);
      } finally {
        loading.value = false;
      }
    }

    return {
      dialogForgetPWD,
      logo,
      isPasswordVisible,
      email,
      password,
      icons,
      loading,
      resetPassword,
      login,
    };
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
.text-h5 {
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: inter, sans-serif, -apple-system, blinkmacsystemfont, Segoe UI,
    roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol !important;
  text-transform: none !important;
}
.iconFacebook {
  color: #4267b2 !important;
  margin-right: 10px;
}
.iconGoogle {
  color: #db4437 !important;
}
.socialLogin {
  text-transform: unset !important;
  font-weight: bold;
  text-align: center;
}
</style>
